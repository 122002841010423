/* eslint-disable */
import { ref, watch, computed } from "@vue/composition-api";
export default function useEmployeesList() {
  const refEmployeeListTable = ref(null);

  const perPage = ref(10);
  const totalEmployees = ref(0);
  const currentPage = ref(1);

  const dataMeta = computed(() => {
    const localItemsCount = refEmployeeListTable.value
      ? refEmployeeListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalEmployees.value,
    };
  });

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const employeeProfile = (status) => {
    if (status === "PROGRAMMER") return "Programador";
    if (status === "TESTER") return "Testador";
    if (status === "MANAGER") return "Gerente";
    if (status === "ANALYST") return "Analista";
    if (status === "ARCHITECT") return "Arquiteto";
    if (status === "TRAINEE") return "Estágiario";
    if (status === "UNDEFINED") return "Indefinido";
    return "Não informado";
  };

  return {
    perPage,
    currentPage,
    totalEmployees,
    dataMeta,
    refEmployeeListTable,

    employeeProfile,
  };
}
